import React from "react";
import {Dropdown} from "react-bootstrap";
import { DefinedRange, createStaticRanges } from 'react-date-range';
import { useState, useEffect } from 'react';
import axios from "axios";
import moment from "moment";

import {DropdownCustomToggler} from "../dropdowns";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingOverlay, Loader } from 'react-overlay-loader';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay,
  differenceInCalendarDays,
} from 'date-fns';

export const DASHBOARD_URL = "dashboard";

export function Demo1Dashboard() {

    const [dashboardStats, setDashboardStats] = useState(false);
    const [loading, setLoader] = useState(false);
    const [hasError, setErrors] = useState(false);

    async function fetchData(dateRange) {
      setLoader(true);
      let paramsArray = JSON.stringify({"startDate": moment(dateRange?.startDate).format('YYYY-MM-D'), "endDate": moment(dateRange?.endDate).format('YYYY-MM-D')});
      console.log("paramsArray: ", paramsArray);
      axios.post(DASHBOARD_URL, { paramsArray })
      .then(response => {
            console.log(response.data);
            setDashboardStats(response.data.data);
            setLoader(false);
          //set state object there...
        })
        .catch(error => {
          setErrors(error);
          setLoader(false);
      });
    }
    useEffect(() => {
      fetchData({"startDate": startOfDay(addDays(new Date(), -7)), "endDate": endOfDay(new Date())});
  }, []  );

    const rangesArr = [
        ...createStaticRanges([
            {
                label: 'Today',
                range: () => ({
                    startDate: startOfDay(new Date()),
                    endDate: endOfDay(new Date()),
                })
            },
            {
                label: 'Last 7 Days',
                range: () => ({
                    startDate: startOfDay(addDays(new Date(), -7)),
                    endDate: endOfDay(new Date()),
                }),
            },
            {
                label: 'Last 30 Days',
                range: () => ({
                    startDate: startOfDay(addDays(new Date(), -30)),
                    endDate: endOfDay(new Date()),
                }),
            },
            {
                label: 'Last 90 Days',
                range: () => ({
                    startDate: startOfDay(addDays(new Date(), -90)),
                    endDate: endOfDay(new Date()),
                }),
            },
            {
                label: 'Last Year',
                range: () => ({
                    startDate: startOfDay(addDays(new Date(), -365)),
                    endDate: endOfDay(new Date()),
                }),
            },
            {
                label: 'Current Year',
                range: () => ({
                    startDate: startOfDay(new Date(new Date().getFullYear(), 0, 1)),
                    endDate: endOfDay(new Date()),
                }),
            }
        ])
    ];

    const [state, setState] = useState([
      {
        startDate: startOfDay(addDays(new Date(), -7)),
        endDate: endOfDay(new Date()),
        key: 'selection'
      }
    ]);

    const rangeChange = (item) => {
      console.log("date range: ", item);
      setState([item.selection]);
      fetchData(item.selection);
    };

    const dateRangeText = () => {
        if(moment(state[0]?.startDate).year() === moment(state[0]?.endDate).year()) {
            if(moment(state[0]?.startDate).month() === moment(state[0]?.endDate).month()) {
                return moment(state[0]?.startDate).format('MMM D')  + '-' +  moment(state[0]?.endDate).format('D, YYYY');
            }
            else{
                return moment(state[0]?.startDate).format('MMM D')  + '-' +  moment(state[0]?.endDate).format('MMM D, YYYY');
            }
        }
        else
            return moment(state[0]?.startDate).format('MMM D, YYYY')  + '-' +  moment(state[0]?.endDate).format('MMM D, YYYY');
    };
    console.log(state);

    const statsWidget = (className, iconName, color, widgetTitle, count) => {
        return (
          <div className={`card card-custom bg-white mt-4 ${className}`}>
            {/* Body */}
            <div className="card-body position-relative overflow-hidden">
              <div className="row justify-content-center">
                <div className="col-md-4 my-auto">
                <i style={{ color: color, 'fontSize': '3em' }} className={`fa fa-${iconName}`}></i>

                </div>
                <div className="col-md-8">
                  <div className="row">
                    <h4>{widgetTitle}</h4>
                  </div>
                  <div className="row">
                    <h1>{count}</h1>
                  </div>
                </div>
              </div>

            </div>
          </div>
        );
    }
    return (<>
            <LoadingOverlay style={{ width: "100%", height: 'auto', backgroundColor: '#222222' }}>

                <Loader loading={loading}/>
            </LoadingOverlay>
            <div className="row">
                <div className="col-md-12">
                <Dropdown className="dropdown-inline calendarbox" alignRight>
                  <Dropdown.Toggle
                    className="btn btn-color-primary btn-clean btn-hover-light-primary btn-icon"
                    variant="transparent"
                    id="dropdown-toggle-top"
                    as={DropdownCustomToggler}
                   >
                    <i className="ki ki-calendar btn btn-color-primary btn-active-light-primary" />
                    { dateRangeText() }
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                      <DefinedRange
                          onChange={item => rangeChange(item)}
                          ranges={state }
                          staticRanges= {rangesArr}
                      />
                  </Dropdown.Menu>
                </Dropdown>

                </div>
            </div>
            <div className="row">

                <div className="col-md-5 col-xxl-6">
                    <div className="row bg-light mx-1">
                        <div className="col-md-6 col-xxl-6">
                            { statsWidget("card-stretch gutter-b", 'handshake', "#FF8900", 'Co-Ownerships', dashboardStats? dashboardStats.coOwnerships : 0) }

                        </div>
                        <div className="col-lg-6 col-xxl-6">
                            { statsWidget("card-stretch gutter-b", 'file-alt', "#BE2EB5", 'Flying Clubs', dashboardStats? dashboardStats.clubs : 0) }

                        </div>
                    </div>
                    <div className="row bg-light mx-1">
                        <div className="col-md-6 col-xxl-6">
                            { statsWidget("card-stretch gutter-b", 'briefcase', "#BE2EB5", 'Business Listings', dashboardStats? dashboardStats.businesslistings : 0 ) }

                        </div>
                        <div className="col-lg-6 col-xxl-6">
                            { statsWidget("card-stretch gutter-b", 'envelope', "#2199D5", 'Email Invites to non-members', dashboardStats? dashboardStats.emailInvites : 0 ) }

                        </div>
                    </div>

                </div>
                <div className="col-md-5 col-xxl-6">
                    <div className="row bg-light">
                        <div className="col-md-6 col-xxl-6">
                            { statsWidget("card-stretch gutter-b", 'user-friends', "#FF8900", 'Registered Pilots', dashboardStats? dashboardStats.registered_pilots : 0 ) }

                        </div>
                        <div className="col-lg-6 col-xxl-6">
                            { statsWidget("card-stretch gutter-b", 'user-friends', "#BE2EB5", 'Student Pilots', dashboardStats? dashboardStats.student_pilots : 0 ) }

                        </div>
                    </div>
                    <div className="row bg-light">
                        <div className="col-md-6 col-xxl-6">
                            { statsWidget("card-stretch gutter-b", 'user-friends', "#BE2EB5", 'Non Pilots', dashboardStats? dashboardStats.unregistered_pilots : 0 ) }

                        </div>
                        <div className="col-lg-6 col-xxl-6">
                            { statsWidget("card-stretch gutter-b", 'plane', "#2199D5", 'iSorties', dashboardStats? dashboardStats.iSorties : 0 ) }

                        </div>
                    </div>

                </div>



            </div>

            {/*
            <div className="row">
                <div className="col-md-6 col-xxl-6">
                    <div className="col-md-12 py-4">
                        <h3>Riders</h3>
                    </div>
                    <div className="row bg-light mx-1">
                        <div className="col-md-6 col-xxl-6 my-auto">
                            <MixedWidget14 className="card-stretch gutter-b" />

                        </div>
                        <div className="col-md-6 col-xxl-6  my-auto">
                            <MixedWidget15 className="card-stretch gutter-b" />

                        </div>

                    </div>


                </div>
                <div className="col-md-6 col-xxl-6">
                    <div className="col-md-12 py-4">
                        <h3>Drivers</h3>
                    </div>
                    <div className="row bg-light mx-1">
                        <div className="col-md-6 col-xxl-6 my-auto">
                            <MixedWidget14Driver className="card-stretch gutter-b" />

                        </div>
                        <div className="col-md-6 col-xxl-6  my-auto">
                            <MixedWidget15Driver className="card-stretch gutter-b" />

                        </div>

                    </div>


                </div>



            </div>
        */}
    </>);
}
